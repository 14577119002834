import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import 'element-ui/lib/theme-chalk/index.css';
import {constantRoutes} from "./router";
import { Input, Icon, Button} from 'element-ui';
Vue.config.productionTip = false
Vue.use(Input)
Vue.use(Icon)
Vue.use(Button)
const router = new VueRouter({
  base:  '/',
  mode:  'history',
  routes: constantRoutes
});
router.afterEach(()=>{
  window.scrollTo(0,0);
})
new Vue({
  router,
  scrollBehavior: () => ({
    y: 0
  }),
  render: h => h(App),
}).$mount('#app')
