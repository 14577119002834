<template>
    <div id="app">
        <div class="zx-nav-bar">
            <div class="zx-nav-box">
                <img class="zx-logo" style="width: 108px;" src="./assets/logo.png">
                <ul class="zx-nav">
                    <li :class="item.path.includes($route.path) && $route.path !='/' ? 'active':''" v-for="(item, index) in navlist" :key="index" @click="routerChange(item)" @mouseenter="navEnter(item)" @mouseleave="navSubState = false" :id="item.id">
                        <a>{{item.name}}</a>
                    </li>
                </ul>
            </div>
            <div
                :class="{
                    'sub-nav-bar': true,
                    'hover': navSubState
                }"
                @mouseenter="navSubState = true"
                @mouseleave="navSubState = false"
            >
                <div :class="{
                    'sub-nav-box': true,
                    'hover': navSubState
                }" :style="`left: ${navX}px`">
                    <transition-group name="slide-fade">
                        <template v-if="subNavType == 'product'">
                            <div v-for="(item, index) in subNavProduct" :key="`prod${index}`"
                                 v-show="navSubState"
                                 @click="subNavGoto(item)"
                            >
                                <a>{{item.name}}</a>
                            </div>
                        </template>
                        <template v-else>
                            <div v-for="(item, index) in subNavAboutUs" :key="`about${index}`"
                                 v-show="navSubState"
                                 @click="subNavGoto(item)"
                            >
                                <a>{{item.name}}</a>
                            </div>
                        </template>
                    </transition-group>
                </div>
            </div>
        </div>
        <transition name="slide-fade">
            <router-view style="min-height: 100%;padding-top: 83px;" />
        </transition>
        <div class="zx-footer" id="aboutUs">
            <div class="zx-footer-bottom" style="width: 430px;">
                <img src="./assets/product/foot-log.png" alt="">
                <p><img src="./assets/product/phone-icon.png"><a href="tel:0592-5516651">0592-5516651</a></p>
                <p><img src="./assets/product/email-icon.png"><a href="mailto:sunsing@amicloud.cn">sunsing@amicloud.cn</a></p>
                <p><img src="./assets/product/loc-icon.png">福建省厦门市湖里区和通里98号9层</p>
            </div>
            <div class="zx-footer-box">
                <div class="zx-footer-top">
                    <ul>
                        <li class="title">产品</li>
                        <li>慧聚</li>
                        <li>慧通</li>
                        <li>慧治</li>
                    </ul>
                    <ul>
                        <li class="title">解决方案</li>
                        <li>银账保</li>
                    </ul>
                    <ul>
                        <li class="title">客户案例</li>
                        <li>数据要素服务平台</li>
                        <li>账户风险防控平台</li>
                    </ul>
                    <ul>
                        <li class="title">关于我们</li>
                        <li>公司简介</li>
                        <li>加入我们</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="zx-end">@2010-2022厦门掌讯信息技术有限公司sunsing.top   <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备17000432号-3</a></div>
    </div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Swiper from "swiper"

    export default {
        name: 'App',
        data(){
            return {
                navX: 0,
                curNavName: 'index',
                subNavType: '',
                navlist:[
                    {
                        name:'首页',
                        id: 'index',
                        path:'/index'
                    },
                    {
                        name:'产品',
                        id: 'product',
                        path:'/producthj,/productht,/producthz'
                    },
                    {
                        name:'解决方案',
                        id: 'solution',
                        path: '/solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners',
                        path: '/partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs',
                        path: '/aboutUs,/recruit,/detail'
                    }
                ],
                subNavProduct: [{
                    id:'product',
                    name:'慧聚',
                    fName: 'product',
                    productName:'hj'
                },{
                    id:'product',
                    name:'慧通',
                    fName: 'product',
                    productName:'ht'
                },{
                    id:'product',
                    name:'慧治',
                    fName: 'product',
                    productName:'hz'
                }],
                subNavAboutUs: [{
                    id:'aboutUs',
                    name:'公司介绍',
                    fName: 'aboutUs',
                    productName:'hj'
                },{
                    id:'recruit',
                    name:'加入我们',
                    fName: 'aboutUs',
                    productName:'ht'
                }],
                navSubState: false,
            }
        },
        mounted() {
            new Swiper('.swiper-container1', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                autoplay: { //自动开始
                    delay: 2500, //时间间隔
                    disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                },
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1',
                },
            })
            new Swiper('.swiper-container2', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                // autoplay: { //自动开始
                //     delay: 2500, //时间间隔
                //     disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                // },
                slidesPerView: 4,
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2',
                },
            })
        },
        methods:{
            navEnter(navData){
                if(navData.name == '产品' || navData.name == '关于我们'){
                    this.navSubState = true
                    this.subNavType = navData.id
                    this.navX = document.getElementById(navData.id).getBoundingClientRect().left + 22
                }
            },
            routerChange(navData){
                console.log(navData.id);
                if(navData.name == '产品' || navData.name == '关于我们'){
                    return
                }else{
                    this.curNavName = navData.id;
                    this.$router.push(`/${navData.id}`)
                }
            },
            subNavGoto(subNavData){
                this.curNavName = subNavData.fName;
                if(this.subNavType == 'product'){
                    this.$router.push(`/${subNavData.id}${subNavData.productName}`)
                }else{
                    this.$router.push(`/${subNavData.id}`)
                }
            }
        }
    }
</script>

<style>
    * {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .slide-fade-enter,.slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
        opacity: 0;
        transition: all .5s ease;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    html, body {
        position: relative;
        width: 100%;
        height: 100%;
    }


    /*再自定义样式*/
    .swiper-button-prev1 {
        width: 46px;
        height: 77px;
        background: url('./assets/banner-arr-left.png') no-repeat center center;
        bottom: 15px;
        border: 1px solid rgba(208, 208, 208, 0.25);
    }

    .swiper-button-next1 {
        width: 46px;
        height: 77px;
        background: url('./assets/banner-arr-right.png') no-repeat center center;
        bottom: 15px;
        border: 1px solid rgba(208, 208, 208, 0.25);
    }
    /*再自定义样式*/
    .swiper-button-prev2 {
        width: 10px;
        height: 19px;
        background: url('./assets/fo-left.png') no-repeat center center;
        background-size: 100%;
        margin-top: -10px;
    }

    .swiper-button-next2 {
        width: 10px;
        height: 19px;
        background: url('./assets/fo-right.png') no-repeat center center;
        background-size: 100%;
        margin-top: -10px;
    }
</style>

<style lang="scss" scoped>

    .sub-nav-bar{
        width: 100%;
        background-color: #fff;
        height: 0px;
        position: fixed;
        top: 83px;
        transition: height .5s;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    }
    .sub-nav-box{
        position: absolute;
        opacity: 0;
        transition: opacity .5s;
        a{
            display: block;height: 40px;
            text-decoration: none;
            color: #000000;
            width: 100%;
            display: inline-block;
            cursor: pointer;
            transition: all .5s;
        }
        a:hover{
            color: #305DC4;
            transition: all .5s;
        }
        >div:hover {
            color: #305DC4;
            cursor: pointer;
            transition: all .5s;
            a{
                color: #305DC4;
                transition: all .5s;
            }
        }
        &.hover{
            opacity: 1;
            transition: opacity .5s;
        }
    }
    .sub-nav-bar.hover{
        height: 141px;
        transition: height .5s;
    }
    .zx-nav-bar {
        height: 83px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
        .zx-nav-box {
            width: 1200px;
            height: 83px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .zx-nav {
            display: flex;
            height: 83px;
            align-items: center;

            li {
                padding: 0 22px;
                height: 83px;
                line-height: 83px;
                transition: all .5s;
                position: relative;
            }
            a{
                text-decoration: none;
                color: #000000;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
            li:hover {
                color: #305DC4;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #305DC4;
                    transition: all .5s;
                }
            }

            li.active {
                color: #305DC4;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #305DC4;
                    transition: all .5s;
                }
                &::after{
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 2px;
                    background: #305DC4;
                    border-radius: 6px;
                    bottom: 24px;
                    left: 50%;
                    margin-left: -6px;
                }
            }
        }
    }

    .zx-banner-bar {
        margin-top:83px;
        height: 595px;
    }

    .swiper-container1 {
        height: 100%;
        background: #151419;
    }

    .zx-banner-list {
        position: relative;
        height: 100%;
        overflow: hidden;

        img.banner-1 {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-1039px, 0);
        }

        img.banner-2 {
            position: absolute;
            left: 50%;
            bottom: 143px;
            transform: translate(-700px, 0);
        }

        .banner-text {
            position: absolute;
            width: 690px;
            left: 45%;
            top: 112px;
            transform: translate(50px, 0);
            display: flex;
            flex-direction: column;

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 56px;
                line-height: 78px;
                color: #FFFFFF;
                margin-bottom: 20px;

                span {
                    color: #85B3FF;
                }
            }

            p {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 300;
                font-size: 28px;
                line-height: 176.02%;
                color: #FFFFFF;
                text-align: left;
            }
            .button-bar{
                display: flex;
                justify-content: flex-end;
                margin-top: 50px;
            }

            a {
                display: block;
                color: #305DC4;
                width: 194px;
                height: 60px;
                line-height: 60px;
                left: 1554px;
                top: 526px;
                background: #FFFFFF;
                border-radius: 2px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                color: #305DC4;
                cursor: pointer;
                transition: all .5s;
            }
            a:hover{

                background: #305DC4;
                color: #fff;
                transition: all .5s;
            }
        }
    }

    .zx-product-bar{
        width: 100%;
        height: 750px;
        display: flex;
        justify-content: center;
        background: #ffffff;
    }
    .zx-product-box{
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .zx-product-title{
            margin-top: 109px;
            margin-bottom: 80px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }
        .zx-product-sub-nav{
            display: flex;
            width: 390px;
            justify-content: space-between;
            margin-bottom: 45px;
            li{
                width: 120px;
                height: 43px;
                line-height: 43px;
                left: 761px;
                top: 974px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: inherit;
            }
        }
        .zx-product-list{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 66px;
            li{
                width: 380px;
                height: 391px;
                background: #FFFFFF;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
                display: flex;
                align-items: center;
                flex-direction: column;

                img{
                    width: 380px;
                    height: 195px;
                    margin-bottom: 7px;
                }
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 34px;
                    color: #000000;
                    margin-bottom: 10px;
                }
                p{
                    width: 330px;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 29px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
        .more-btn{
            box-sizing: border-box;
            width: 158px;
            height: 41px;
            padding-right: 40px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
            align-items: center;
            line-height: 41px;
            position: relative;
            cursor: pointer;
            transition: all .5s;
            &:hover{
                background-color: #305DC4;
                color: #fff;
                transition: all .5s;
            }
            &::after{
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0px;
                top: -1px;
                background: #000000;
            }
            .array{
                position: absolute;
                right: 10px;
                z-index: 2;
                top: 15px;
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
    }
    .zx-solution-box{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .zx-solution-title{
            margin-top: 107px;
            margin-bottom: 64px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-solution-banner{
            width: 100%;
            height: 548px;
            background-image: url("./assets/solution-banner.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
        .zx-solution-block{
            width: 100%;
            height: 548px;
            background-color: rgba(34, 53, 96, 0.95);
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .zx-solution-content{
            width: 1214px;
            height: 394px;
            background: #305DC4;
            transform: translate(0, 226px);
            display: flex;
            justify-content: space-between;
            padding:  0 27px;
            box-sizing: border-box;
            img{
                transform: translate(0, -32px);
            }
            >div{
                width: 506px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 76px;
                margin-right: 50px;
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 1;
                    color: #FFFFFF;
                    margin-bottom: 54px;
                    position: relative;
                    &::after{
                        content: '';
                        width: 42px;
                        height: 2px;
                        background: #FFFFFF;
                        border-radius: 5px;
                        position: absolute;
                        left: 0;
                        top:45px
                    }
                }
                p{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    color: #FFFFFF;
                    text-align: left;
                }
                >div{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    a{
                        width: 68px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
        margin-bottom: 190px;
    }
    .zx-partners-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #dfdfdf;

        .zx-partners-title {
            margin-bottom: 93px;
            position: relative;

            h2 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }

            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }

            &::before {
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-partners-list{
            display: flex;
            width: 1200px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 81px;
            botder-bottom: 1px solid rgba(217, 217, 217, 0.9);
        }
    }
    .swiper-container2{
        width: 1200px;
        ::v-deep .swiper-slide{
            height: 69px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .zx-footer{
        height: 434px;
        background: #F1F1F1;
        display: flex;
        justify-content: center;
    }

    .zx-footer-box{
        display: flex;padding-right: 100px;box-sizing: border-box;
        width: 800px;
        flex-direction: column;
        >div{
            display: flex;
        }
        .zx-footer-top{
            justify-content: space-between;
            padding-top: 43px;
            margin-bottom: 0px;
        }
        ul{
            display: flex;
            flex-direction: column;
            text-align: left;
            li.title{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 40px;
            }
            li{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 40px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .zx-footer-bottom{
        margin-top: 43px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        >img{
            margin-bottom: 43px;
        }
        p{
            display: flex;
            align-items: center;
            margin-bottom: 43px;
            img{
                margin-right: 10px;
            }
        }
        a{
            list-style: none;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .zx-end{
        width: 1200px;
        margin: 0 auto;
        height: 63px;
        display: flex;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        a{
            font-size: 20px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.5);
            text-decoration: initial;
            margin-left: 10px;
        }
    }
</style>


<style lang="scss">


    .zx-new-product-bar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .zx-new-product-list{
            width: 340px;
            height: 440px;
            border: 0.5px solid rgba(0, 0, 0, 0.0);
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                color: #121212;
                margin-bottom: 20px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: rgba(18, 18, 18, 0.75);
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                background: rgba(252, 252, 252, 0.5);
                border: 0.5px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                transition: all .5s;
            }
        }
    }


    .zx-new-partners-list-bar{
        display: flex;
        width: 1060px;
        justify-content: space-between;
        flex-wrap: wrap;
        .zx-new-partners-list{
            width: 520px;
            height: 370px;
            background: #FDFDFD;
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                color: #2653B8;
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                margin-top: 91px;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                transition: all .5s;
            }
        }
        .zx-new-partners-list:nth-child(1){
            background:url("./assets/product/parner-bg-1.jpg") no-repeat 68px 40px;
        }
        .zx-new-partners-list:nth-child(2){
            background:url("./assets/product/parner-bg-2.jpg") no-repeat 107px 54px ;
        }
        .zx-new-partners-list:nth-child(3){
            background:url("./assets/product/parner-bg-3.png") no-repeat 63px 18px;
        }
        .zx-new-partners-list:nth-child(4){
            background:url("./assets/product/parner-bg-4.png") no-repeat 121px 76px;
        }
    }


    .zx-new-aoubt-us-bar{
        h2{
            ont-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: #000000;
            margin-bottom: 20px;
        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 214%;
            color: rgba(18, 18, 18, 0.75);
        }

        margin-bottom: 50px;
    }
</style>
