import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

export const constantRoutes = [
  {
    path: "*",
    redirect: '/index',
  },
  {
    path: '/index',
    component: (resolve) => require(['../components/index/index.vue'], resolve),
  },
  {
    path: '/producthj',
    component: (resolve) => require(['../components/prodcut/index.vue'], resolve),
  },
  {
    path: '/productht',
    component: (resolve) => require(['../components/prodcut/index1.vue'], resolve),
  },
  {
    path: '/producthz',
    component: (resolve) => require(['../components/prodcut/index2.vue'], resolve),
  },
  {
    path: '/solution',
    component: (resolve) => require(['../components/programme/index.vue'], resolve),
  },
  {
    path: '/partners',
    component: (resolve) => require(['../components/parner/index.vue'], resolve),
  },
  {
    path: '/aboutUs',
    component: (resolve) => require(['../components/aboutUs/index.vue'], resolve),
  },
  {
    path: '/recruit',
    component: (resolve) => require(['../components/recruit/index.vue'], resolve),
  },
  {
    path: '/detail',
    component: (resolve) => require(['../components/recruit/detail.vue'], resolve),
  },
]
