var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"zx-nav-bar"},[_c('div',{staticClass:"zx-nav-box"},[_c('img',{staticClass:"zx-logo",staticStyle:{"width":"108px"},attrs:{"src":require("./assets/logo.png")}}),_c('ul',{staticClass:"zx-nav"},_vm._l((_vm.navlist),function(item,index){return _c('li',{key:index,class:item.path.includes(_vm.$route.path) && _vm.$route.path !='/' ? 'active':'',attrs:{"id":item.id},on:{"click":function($event){return _vm.routerChange(item)},"mouseenter":function($event){return _vm.navEnter(item)},"mouseleave":function($event){_vm.navSubState = false}}},[_c('a',[_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{class:{
                'sub-nav-bar': true,
                'hover': _vm.navSubState
            },on:{"mouseenter":function($event){_vm.navSubState = true},"mouseleave":function($event){_vm.navSubState = false}}},[_c('div',{class:{
                'sub-nav-box': true,
                'hover': _vm.navSubState
            },style:(`left: ${_vm.navX}px`)},[_c('transition-group',{attrs:{"name":"slide-fade"}},[(_vm.subNavType == 'product')?_vm._l((_vm.subNavProduct),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navSubState),expression:"navSubState"}],key:`prod${index}`,on:{"click":function($event){return _vm.subNavGoto(item)}}},[_c('a',[_vm._v(_vm._s(item.name))])])}):_vm._l((_vm.subNavAboutUs),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navSubState),expression:"navSubState"}],key:`about${index}`,on:{"click":function($event){return _vm.subNavGoto(item)}}},[_c('a',[_vm._v(_vm._s(item.name))])])})],2)],1)])]),_c('transition',{attrs:{"name":"slide-fade"}},[_c('router-view',{staticStyle:{"min-height":"100%","padding-top":"83px"}})],1),_vm._m(0),_vm._m(1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zx-footer",attrs:{"id":"aboutUs"}},[_c('div',{staticClass:"zx-footer-bottom",staticStyle:{"width":"430px"}},[_c('img',{attrs:{"src":require("./assets/product/foot-log.png"),"alt":""}}),_c('p',[_c('img',{attrs:{"src":require("./assets/product/phone-icon.png")}}),_c('a',{attrs:{"href":"tel:0592-5516651"}},[_vm._v("0592-5516651")])]),_c('p',[_c('img',{attrs:{"src":require("./assets/product/email-icon.png")}}),_c('a',{attrs:{"href":"mailto:sunsing@amicloud.cn"}},[_vm._v("sunsing@amicloud.cn")])]),_c('p',[_c('img',{attrs:{"src":require("./assets/product/loc-icon.png")}}),_vm._v("福建省厦门市湖里区和通里98号9层")])]),_c('div',{staticClass:"zx-footer-box"},[_c('div',{staticClass:"zx-footer-top"},[_c('ul',[_c('li',{staticClass:"title"},[_vm._v("产品")]),_c('li',[_vm._v("慧聚")]),_c('li',[_vm._v("慧通")]),_c('li',[_vm._v("慧治")])]),_c('ul',[_c('li',{staticClass:"title"},[_vm._v("解决方案")]),_c('li',[_vm._v("银账保")])]),_c('ul',[_c('li',{staticClass:"title"},[_vm._v("客户案例")]),_c('li',[_vm._v("数据要素服务平台")]),_c('li',[_vm._v("账户风险防控平台")])]),_c('ul',[_c('li',{staticClass:"title"},[_vm._v("关于我们")]),_c('li',[_vm._v("公司简介")]),_c('li',[_vm._v("加入我们")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zx-end"},[_vm._v("@2010-2022厦门掌讯信息技术有限公司sunsing.top "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("闽ICP备17000432号-3")])])
}]

export { render, staticRenderFns }